import React from "react";
import { Grid } from "@material-ui/core";
import whitepaper from "../../assets/whitepaper/Ghost_Privacy_Whitepaper.pdf";
import {FaqContainer} from "./index";

const FaqGeneral = () => {
  return (
      <Grid container>
        <FaqContainer title="What is Ghost?">
          Ghost is a Proof of Stake privacy coin to help make you nothing
          but a "ghost" when transacting online!
        </FaqContainer>
        <FaqContainer title="What are the future plans for Ghost?">
          You can view more information about this in our{" "}
          <a href = {whitepaper} rel="noreferrer" target = "_blank" style={{ color: "#AEFF00" }}>Whitepaper</a>
        </FaqContainer>
        <FaqContainer title="What is the average speed of a Ghost public/private transaction?">
          Transactions will show up in your wallet in less than a second.
          For the first confirmation it usually takes 1-2 minute.
        </FaqContainer>
        <FaqContainer title="What are the fees for sending a transaction?">
          A fraction of a penny.
        </FaqContainer>
        <FaqContainer title="Where can I see the status and transactions on the Ghost blockchain?">
          The status of the Ghost Blockchain, peers, blocks, and transactions can be found on{" "}
          <a href="https://ghostscan.io/" style={{ color: "#AEFF00" }}>https://ghostscan.io/</a> or <a href="https://cloud.ghostin.io/#/" style={{ color: "#AEFF00" }}>https://www.ghostin.io/</a>
        </FaqContainer>
      </Grid>
  );
};

export default FaqGeneral;
